<template>
  <div class="px-9 my-5">
    <v-alert
      type="error"
      v-if="log.blocked_by_recipient"
    >
      Blocked by recipient
    </v-alert>
    <v-list-item-group class="mb-5">
      <v-list-item-content class="py-4 pl-0">
        <v-col class="pl-0" cols="6">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Device Type</s-text>
          <s-text weight="bold" size="lg" color="black">
            {{ log.mail_type || 'Unknown type' }}
          </s-text>
        </v-col>
        <v-col class="pl-0" cols="6">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Delivery Delay</s-text>
          <s-text weight="bold" size="lg" color="black">
            {{ log.e2e_msg_type || 'Unknown e2e type' }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content class="py-4 pl-0 top-thin-border">
        <v-col class="pl-0" cols="6">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Delivery Status</s-text>
          <s-text weight="bold" size="lg" color="black">
            {{ log.delivered ? 'Delivered' : 'Undelivered' }}
          </s-text>
        </v-col>
        <v-col class="pl-0" cols="6">
          <s-text weight="normal" size="sm" color="grayTextAlt" class="mb-2">Delivery Delay</s-text>
          <s-text weight="bold" size="lg" color="black">
            {{ log.delivery_delay }}mins
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Delivered At
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ log.delivered_at ? getHumanReadableDate(log.delivered_at) : "-" }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Message ID
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ log.msg_id || 'Unknown' }}
          </s-text>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            From
          </s-text>
          <s-link
            size="md-m"
            weight="bold"
            color="grayTextAlt"
            :to="{ name: 'appDetails', params: { id: log.from } }"
          >
            {{ log.from }}
          </s-link>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            To
          </s-text>
          <s-link
            size="md-m"
            weight="bold"
            color="grayTextAlt"
            :to="{ name: 'appDetails', params: { id: log.to } }"
          >
            {{ log.to }}
          </s-link>
        </v-col>
      </v-list-item-content>
      <v-list-item-content :ripple="false" class="top-thin-border bottom-thin-border">
        <v-col class="pl-0">
          <s-text
            weight="normal"
            size="sm"
            color="grayTextAlt"
            class="mb-4"
          >
            Created At
          </s-text>
          <s-text size="md-m" weight="bold">
            {{ log.created_at ? getHumanReadableDate(log.created_at) : "-" }}
          </s-text>
        </v-col>
      </v-list-item-content>
    </v-list-item-group>
    <s-btn
      @click="goBack"
      type="button"
      color="primary"
      elevation="0"
    >
      OK
    </s-btn>
  </div>
</template>

<script>
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'ViewDeviceDetails',
  props: {
    log: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logLocationInfo () {
      const state = this.log.log_location.address.state
      const country = this.log.log_location.address.country
      const getSeparator = () => {
        if (state && country) {
          return ', '
        }

        return ''
      }

      let _location = `${state}${getSeparator()}${country}`
      _location = _location === ''
        ? 'Unknown location'
        : _location

      return _location
    },
  },
  methods: {
    getHumanReadableDate,
    goBack () {
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  border-radius: 6px;
  color: var(--v-gray-base);
  font-size: 14px;
}
</style>
