<template>
  <loader
    v-if="isLoading"
    message="Loading ...."
  />
  <div v-else>
    <v-container class="pa-0">
      <v-row class="pa-1 ma-0 my-3" style="background: #e0f4ff; border-radius: 8px;">
        <v-col class="d-flex flex-column py-0" cols="1">
          <s-text weight="medium" color="gray" size="sm">
            Type
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            From
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="3">
          <s-text weight="medium" color="gray" size="sm">
            To
          </s-text>
        </v-col>
        <v-col class="d-flex flex-column py-0" cols="4">
          <s-text weight="medium" color="gray" size="sm">
            Date Sent
          </s-text>
        </v-col>
      </v-row>
      <v-row
        v-for="log in logs"
        :key="log.id"
        class="pa-0 ma-0 mb-3 app-card app-card-content"
        @click="openLogDetails(log)"
      >
        <v-col class="d-flex flex-column" cols="1">
          <div>
            {{ log.mail_type }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <s-link
            weight="medium"
            color="grayTextAlt"
            :to="{ name: 'appDetails', params: { id: log.from } }"
          >
            {{ log.from }}
          </s-link>
        </v-col>
        <v-col class="d-flex flex-column" cols="3">
          <s-link
            weight="medium"
            color="grayTextAlt"
            :to="{ name: 'appDetails', params: { id: log.to } }"
          >
            {{ log.to }}
          </s-link>
        </v-col>
        <v-col class="d-flex flex-column" cols="4">
          <div>
            {{ log.created_at ? getHumanReadableDate(log.created_at) : "-" }}
          </div>
        </v-col>
        <v-col class="action_group d-flex align-center flex-row-reverse" cols="1">
          <s-icon name="arrow-circle-right" class="mr-2" />
        </v-col>
      </v-row>
    </v-container>
    <s-dialog
      v-model="showLogDetailsModal"
      persistent
      isModal
      title="Message Details"
      :close-default="false"
      @close="showLogDetailsModal = false;"
    >
      <view-log-details
        v-if="showLogDetailsModal"
        :log="log"
        @close="showLogDetailsModal = false"
      />
    </s-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ViewLogDetails from '@/components/apps/ViewLogDetails'
import Loader from '@/components/cards/Loader'
import { getHumanReadableDate } from '@/utils/date'
export default {
  name: 'AppLogs',
  components: {
    loader: Loader,
    'view-log-details': ViewLogDetails,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      logs: 'user/currentUserLogs',
    }),
  },
  data () {
    return {
      isLoading: false,
      showLogDetailsModal: false,
      log: null,
    }
  },
  methods: {
    getHumanReadableDate,
    getLogs () {
      this.isLoading = true
      this.$store
        .dispatch('user/getUserLogs', this.id)
        .finally(() => {
          this.isLoading = false
        })
    },
    openLogDetails (log) {
      this.showLogDetailsModal = true
      this.log = log
    },
  },
  watch: {
    logs: {
      handler () {
        if (this.logs) return
        this.getLogs()
      },
      immediate: true,
    },
  },
}
</script>
